// import 'core-js/stable'
import { isIE } from 'react-device-detect'
import React, { useEffect, useRef } from 'react'
import SEO from '../components/common/seo.js'
import Layout from '../components/common/layout'
import Navigation from '../components/navigation/navigation'
import Header from '../components/sections/header'
import News from '../components/sections/news/news'
import About from '../components/sections/about'
import Team from '../components/sections/team/team'
import Services from '../components/services/services'
import Partners from '../components/sections/partners'
import Career from '../components/sections/career'
import NonAvailable from '../components/sections/nonAvailable'

import Map from '../components/sections/map'
import Contact from '../components/sections/contact'
import Cookies from '../components/common/cookies'
import ScrollTrigger from 'gsap/ScrollTrigger'

import gsap from 'gsap'
import References from '../components/sections/references/References.js'
gsap.registerPlugin(ScrollTrigger)
const IndexPage = () => {
	let sections = useRef(null)
	// useEffect(() => {
	// 	let sectionArray = sections.current.querySelectorAll('section')

	// 	sectionArray.forEach((section) => {
	// 		gsap.fromTo(
	// 			section.children,
	// 			{
	// 				x: -15,
	// 				opacity: 0,
	// 			},
	// 			{
	// 				duration: 1,
	// 				x: 0,
	// 				opacity: 1,
	// 				stagger: 0.5,
	// 				scrollTrigger: {
	// 					trigger: section,
	// 					start: '0% 85%',
	// 					// markers: true,
	// 				},
	// 			}
	// 		)
	// 	})
	// }, [])
	return (
		// przenies strony z wylaczonestrony do katalogu page

		<Layout>
			<SEO title="Home" />

			<Navigation />

			<Header />

			<div ref={sections}>
				<News />
				<About />

				<Team />
				<Services />
				<Partners />
				<References />
				<Career />
			</div>

			<Map />
			<Contact />
			<Cookies />
		</Layout>
	)
}
export default IndexPage
