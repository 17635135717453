import React from "react";
import styled from "styled-components";
import { Section, Container, Aside } from "../global";
import { LOGOS } from "../const";
import "pure-react-carousel/dist/react-carousel.es.css";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
const Partners = () => {
  const slidesCount = LOGOS.length;
  return (
    <Aside id='aside'>
      <Title>Zaufali nam</Title>

      <CarouselWrapper
        infinite={true}
        visibleSlides={6}
        isPlaying={true}
        interval={3000}
        naturalSlideWidth={100}
        naturalSlideHeight={250}
        totalSlides={slidesCount}>
        <SliderWrapper>
          {LOGOS.map((item, index) => {
            return (
              <SlideItem index={index} key={index}>
                {item.img.length > 2 ? (
                  <a href={item.url}>
                    <img
                      src={require(`../../images/loga2/${item.img}`)}
                      alt=''
                    />
                  </a>
                ) : (
                  item.title
                )}
              </SlideItem>
            );
          })}
        </SliderWrapper>
      </CarouselWrapper>
    </Aside>
  );
};

export default Partners;
const Title = styled.h4`
  margin-top: 50px;
`;
const SubTitle = styled.p`
  text-align: center;
  margin: 0 auto;
  width: 100%;
`;

const CarouselWrapper = styled(CarouselProvider)`
  display: flex;
  text-align: center;
  justify-content: center;
  height: 150px;
  min-width: 1400px;
  padding: 10px 0;
  background-color: ${(props) => props.theme.color.white.whiteRegular};
`;
const SlideItem = styled(Slide)`
  display: flex;
  position: relative;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;

  padding: 0px 10px;
  min-width: 120px;
  height: 80px;

  /* object-fit: cover; */
  img {
    margin-top: 10px;
    max-width: 100%;
    max-height: 150px;
    /* filter: grayscale(0.5); */
  }
`;

const SliderWrapper = styled(Slider)`
  /* padding: 50px; */
  flex-wrap: nowrap;
  /* height: 500px; */
`;
