export const references = [
	{
		logo: 'natura.png',
		file: 'natura.pdf',
	},
	{
		logo: 'lot.png',
		file: 'lot.pdf',
	},
	{
		logo: 'doctor.webp',
		file: 'doctor.pdf',
	},
	{
		logo: 'mkl.png',
		file: 'mkl.pdf',
	},
	{
		logo: 'suez.png',
		file: 'suez.pdf',
	},
	{
		logo: 'wolw.png',
		file: 'wolw.pdf',
	},
	{
		logo: 'arcadis.jpg',
		file: 'arcadis.pdf',
	},
]
