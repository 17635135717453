import React, { useEffect, useRef } from 'react'
import { isIE } from 'react-device-detect'
import styled from 'styled-components'
import { Section, Container, Flex, Button } from '../../global'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'

import Img from 'gatsby-image'
import { LOGOS } from '../../const'
import BackgroundImage from 'gatsby-background-image'
import { references } from './data'
import gsap, { Power3 } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const References = () => {
	let items = useRef(null)
	useEffect(() => {
		let itemsAll = items.current.querySelectorAll('.item')
		console.log(itemsAll)
		gsap.fromTo(
			'.item',
			{ y: -25, opacity: 0 },
			{
				// duration: 1,
				opacity: 1,
				stagger: 0.2,
				y: 0,
				ease: Power3,
				scrollTrigger: {
					trigger: '#references',
					// toggleActions: 'restart none play  none',
					start: '0% 60%',
					end: '100% 300%',
					// markers: true,
				},
			}
		)
		itemsAll.forEach((item) => {})
	}, [])
	return (
		<Section id="references">
			<Container>
				<h4>Referencje</h4>
				{/* <Content>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse quod, aliquam, a, tenetur reiciendis omnis voluptatem ipsam ipsum repellendus
					nesciunt est non adipisci similique exercitationem cupiditate repellat? Adipisci nemo dolore officia temporibus reiciendis assumenda numquam
					at, odit, excepturi veritatis tempora. Aliquid saepe veniam hic totam eligendi dignissimos harum neque reprehenderit.
				</Content> */}
				<Items ref={items}>
					{references.map((item, index) => {
						const image = require(`../../../images/loga2/${item.logo}`)
						return (
							<Item key={index} href={require(`./referencje/${item.file}`)} target="blank" className="item">
								<Image style={{ backgroundImage: `url(${image})` }} />
							</Item>
						)
					})}
				</Items>
			</Container>
		</Section>
	)
}

export default References
const Content = styled.p`
	width: 90%;
	margin: 0 auto;
	text-align: justify;
`
const Items = styled.article`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding-bottom: 50px;
`
const Item = styled.a`
	opacity: 0;
	display: block;
	border-radius: 5px;
	background-color: #fff;
	margin: 1rem;
	box-shadow: 4px 4px 6px -2px rgba(66, 68, 90, 0.57);
	transition: 0.3s;
	border: 1px solid #f0f0f0;
	&:hover {
		border: 1px solid #d6d6d6;
		/* box-shadow: 1px 1px 10px -4px ${(props) => props.theme.color.black.grey}; */
	}
`

const Image = styled.div`
	border-radius: 5px;
	width: 250px;
	height: 120px;
	background-size: 80%;
	background-repeat: no-repeat;
	background-size: cover;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		/* width: 100%;
		margin: 20px 0; */
	}
`

const Column = styled(Flex)`
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-basis: 48%;
	z-index: 1;
	@media (max-width: ${(props) => props.theme.screen.sm}) {
		width: 100%;
	}
`
